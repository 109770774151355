:root {
  --g1: rgb(98, 0, 234);
  --g2: rgb(236, 64, 122);
  --scalar-black: 2.5;
  --scalar-transparent: 1;
  --black-gap-hor: calc(40px * var(--scalar-black));
  --transparent-gap-hor: calc(4px * var(--scalar-transparent));
  --black-gap-vert: calc(40px * var(--scalar-black));
  --transparent-gap-vert: calc(4px * var(--scalar-transparent));
}

@keyframes background-pan {
  from {
    background-position: 0% center, 0% center, 0% center;
  }

  to {
    background-position: 0% center, 0% center, -200% center;
  }
}

body {
  animation: background-pan 20s linear infinite;
  background:
    repeating-linear-gradient(to right,
      black 0px,
      black var(--black-gap-hor),
      transparent var(--black-gap-hor),
      transparent calc(var(--transparent-gap-hor) + var(--black-gap-hor))),
    repeating-linear-gradient(to bottom,
      black 0px,
      black var(--black-gap-vert),
      transparent var(--black-gap-vert),
      transparent calc(var(--transparent-gap-vert) + var(--black-gap-vert))),
    linear-gradient(to right,
      var(--g1),
      var(--g2),
      var(--g1));
  background-size: calc(var(--transparent-gap-hor) + var(--black-gap-hor)) 100%, 100% calc(var(--transparent-gap-vert) + var(--black-gap-vert)), 200%;
  background-position: 0% center, 0% center, 0% center;
  image-rendering: pixelated;
  margin: 0px;
  background-attachment: fixed;
}